import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import "dayjs/locale/pt-br";
import GuestsIcon from '@mui/icons-material/PeopleAlt';
import SearchIcon from "@mui/icons-material/Search";
import Title from "../../components/Title";
import SubTitle from "../../components/SubTitle";
import CardCounter from "../../components/CardCounter";
import { TODOS } from "../../utils/constants";
import { DebounceInput } from "react-debounce-input";
import Loading from '../../components/Loading';
import { Menu, TablePagination } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DrawerDetailGuests from "./components/DrawerDetailGuests";
import { printPDF } from "../../api/precheckinController";
import { addDays } from "../../utils/dateUtils";

const dayjs = require('dayjs')

const columns = [
  { id: "name", label: "Nome", width: 200 },
  { id: "email", label: "E-mail", width: 100 },
  { id: "phone", label: "Telefone", width: 150 },
  { id: "checkin", label: "Entrada", width: 100 },
  { id: "checkout", label: "Saída", width: 100 },
  { id: "tokem", label: "Código", width: 100 },
  { id: "status", label: "Status", width: 100 },
  { id: "details", label: "", width: 0 },
];

const styleRow = {
  fontSize: "14px",
  lineHeight: "20px",
  color: "#252733",
  fontWeight: "600",
  alignItems: "center",
  justifyContent: "center",
};

const styleRowStatus = {
  fontSize: "14px",
  lineHeight: "20px",
  color: "#252733",
  fontWeight: "600",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center"
};

const listStatus = [
  { value: "ACTIVE", label: "Ativo" },
  { value: "CONFIRMED", label: "Confirmado" },
  { value: "WAITING", label: "Aguardando" },
  { value: "EXPIRED", label: "Expirado" },
  { value: "CANCELED", label: "Cancelado" },
  { value: TODOS, label: "Todos" },
];

function GuestsScreen({ rows, loading, reload }) {
  const [search, setSearch] = React.useState("");
  const [status, setStatus] = React.useState(TODOS);
  const [checkinInit, setCheckinInit] = React.useState(dayjs(new Date()));
  const [checkinFinal, setCheckinFinal] = React.useState(dayjs(addDays(new Date(), 15)));

  const [items, setItems] = React.useState([]);

  const [countActiveGuests, setCountActiveGuests] = React.useState(0);
  const [countCofirmedGuests, setCountConfirmedGuests] = React.useState(0);
  const [countWaitingGuests, setCountWaitingGuests] = React.useState(0);
  const [countExpiredGuests, setCountExpiredGuests] = React.useState(0);
  const [countCancelledGuests, setCountCancelledGuests] = React.useState(0);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [itemSelected, setItemSelected] = React.useState(null);
  const [openDetails, setOpenDetails] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    let rowsFiltered = rows;
    if (status !== TODOS) {
      rowsFiltered = rowsFiltered.filter((i) => i.status === status);
    }

    if (status !== TODOS) {
      rowsFiltered = rowsFiltered.filter((i) => i.status === status);
    }

    if (!!search) {
      rowsFiltered = rowsFiltered.filter(
        (i) =>
          i.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
          i.email.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
          i.token.toLowerCase().indexOf(search.toLowerCase()) !== -1
      );
    }
    const date1 = dayjs(checkinInit).format("YYYY-MM-DD");
    const date2 = dayjs(checkinFinal).format("YYYY-MM-DD");

    rowsFiltered = rowsFiltered.filter((i) =>
      i.checkin === date1 || i.checkin === date2
      || dayjs(i.checkin).isBetween(date1, date2)
    );

    rowsFiltered.sort(function (a, b) {
      return new Date(a.checkin) - new Date(b.checkin);
    });

    setCountActiveGuests(rows.filter(i => i.status == 'ACTIVE').length)
    setCountConfirmedGuests(rows.filter(i => i.status == 'CONFIRMED').length)
    setCountWaitingGuests(rows.filter(i => i.status == 'WAITING').length)
    setCountExpiredGuests(rows.filter(i => i.status == 'EXPIRED').length)
    setCountCancelledGuests(rows.filter(i => i.status == 'CANCELED').length)

    setItems(rowsFiltered);
  }, [search, status, checkinInit, checkinFinal, rows]);

  const Status = {
    ACTIVE: "ACTIVE", // já está no hotel
    CONFIRMED: "CONFIRMED", // ja fex precheckin
    WAITING: "WAITING", // agauradando precheckin
    EXPIRED: "EXPIRED",
    CANCELED: "CANCELED",
  }

  const renderStatus = (status) => {
    let text = ''
    let color = ''

    switch (status) {
      case Status.ACTIVE:
        text = "Ativo"
        color = "#007942"
        break
      case Status.CONFIRMED:
        text = "Confirmado"
        color = "#4177F6"
        break
      case Status.WAITING:
        text = "Aguardando"
        color = "#F09000"
        break
      case Status.EXPIRED:
        text = "Expirado"
        color = "#999999"
        break
      case Status.CANCELED:
        text = "Cancelado"
        color = "#FF3A44"
        break
      default:
        text = "Indefinido"
        color = "#0079"
    }

    return (
      <div style={{ height: 26, display: "flex", width: 105, backgroundColor: color, borderRadius: 50, alignItems: "center", justifyContent: "center" }}>
        <span style={{ textAlign: "center", fontSize: 12, lineHeight: "18px", color: "#FFFFFF", fontWeight: "600", alignItems: "center", justifyContent: "center" }}>
          {text.toUpperCase()}
        </span>
      </div>
    )
  };

  const showDetails = () => {
    setOpenDetails(true);
    handleClose();
  };

  const closeDetails = () => {
    setOpenDetails(false);
    setItemSelected(null);
    reload()
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getPDF = async () => {
    printPDF(itemSelected.hotel_uuid, itemSelected.id)
      .then(response => {
        // Create a Blob from the response data
        const pdfBlob = new Blob([response.data], { type: "application/pdf" });

        // Create a temporary URL for the Blob
        const url = window.URL.createObjectURL(pdfBlob);

        // Create a temporary <a> element to trigger the download
        const tempLink = document.createElement("a");
        tempLink.href = url;

        tempLink.target = "_blank";

        tempLink.setAttribute(
          "print",
          // `${row.name}_${row.room || ''}.pdf`
          `${itemSelected.name || 'hespede'}_${itemSelected.room || ''}.pdf`
        ); // Set the desired filename for the downloaded file

        // Append the <a> element to the body and click it to trigger the download
        document.body.appendChild(tempLink);
        tempLink.click();

        // Clean up the temporary elements and URL
        document.body.removeChild(tempLink);
        window.URL.revokeObjectURL(url);
      })
      .catch(err => {
        console.error(err);
      });
  }

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: '#FFFFFF',
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
      }}
    >
      <Container maxWidth="xl" sx={{ mt: 13, mb: 4 }}>
        <Title text="Hóspedes" icon={<GuestsIcon color='primary' />} />

        <Grid container spacing={3} >
          <Grid item xs={6} md={2.2}>
            <CardCounter count={countActiveGuests} label="Ativos" loading={loading} />
          </Grid>
          <Grid item xs={6} md={2.2}>
            <CardCounter count={countCofirmedGuests} label="Confirmados" loading={loading} />
          </Grid>
          <Grid item xs={6} md={2.2}>
            <CardCounter count={countWaitingGuests} label="Aguardando" loading={loading} />
          </Grid>
          <Grid item xs={6} md={2.2}>
            <CardCounter count={countExpiredGuests} label="Expirado" loading={loading} />
          </Grid>
          <Grid item xs={6} md={2.2}>
            <CardCounter count={countCancelledGuests} label="Cancelado" loading={loading} />
          </Grid>
        </Grid>

      </Container>

      <Container maxWidth="xl" sx={{ mb: 4 }}>
        <Grid container sx={{ mt: 2, mb: 4, zIndex: 1 }} spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            justifyContent="flex-start"
            alignItems="center"
          >
            <FormControl fullWidth>
              <InputLabel htmlFor="outlined-search" size="small">
                O que voçê procura?
              </InputLabel>

              <DebounceInput
                id="outlined-search"
                value={search}
                minLength={1}
                label="Busca"
                debounceTimeout={300}
                onChange={(e) => setSearch(e.target.value)}
                element={OutlinedInput}
                size="small"
                endAdornment={
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={2}
            justifyContent="flex-start"
            alignItems="center"
          >
            <TextField
              fullWidth
              select
              label="Status"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              size="small"
            >
              {listStatus.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={2}
            justifyContent="flex-start"
            alignItems="center"
          >
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
              <DatePicker
                localeText={"pt-br"}
                label="Período de"
                value={checkinInit}
                format="DD/MM/YYYY"
                onChange={(newValue) => setCheckinInit(new Date(newValue))}
                slotProps={{ textField: { size: 'small' } }}
              />
            </LocalizationProvider>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={2}
            justifyContent="flex-start"
            alignItems="center"
          >
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
              <DatePicker
                localeText={"pt-br"}
                label="Período até"
                value={checkinFinal}
                format="DD/MM/YYYY"
                onChange={(newValue) => setCheckinFinal(new Date(newValue))}
                slotProps={{ textField: { size: 'small' } }}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>

        <SubTitle text={'Check-in do dia'} />

        {/* GRID */}
        {loading &&
          <Loading color={'#4177F6'} text={'Carregando...'} />
        }
        {!loading &&
          <Paper sx={{ width: "100%" }}>
            <TableContainer sx={{ maxHeight: 1240 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        width={column.width}
                        style={{
                          width: column.width,
                          backgroundColor: "#F5F6FA",
                          color: "#9FA2B4",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                          <TableCell style={styleRow}>{row.name}</TableCell>
                          <TableCell style={styleRow}>{row.email}</TableCell>
                          <TableCell style={styleRow}>{row.phone}</TableCell>
                          <TableCell style={styleRow}> {dayjs(row.checkin).format("DD/MM/YYYY")} </TableCell>
                          <TableCell style={styleRow}> {dayjs(row.checkout).format("DD/MM/YYYY")} </TableCell>
                          <TableCell style={styleRow}> {row.token} </TableCell>
                          <TableCell style={styleRowStatus}>
                            {renderStatus(row.status)}
                          </TableCell>
                          <TableCell align="right" style={styleRow}>
                            <div>
                              <MoreVertIcon
                                id="demo-positioned-button"
                                aria-controls={
                                  openMenu ? "demo-positioned-menu" : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={openMenu ? "true" : undefined}
                                onClick={(e) => {
                                  setItemSelected(row);
                                  handleClick(e);
                                }}
                              />
                              <Menu
                                id="demo-positioned-menu"
                                aria-labelledby="demo-positioned-button"
                                anchorEl={anchorEl}
                                open={openMenu}
                                onClose={handleClose}
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                              >
                                <MenuItem onClick={(e) => { getPDF() }}>
                                  Imprimir Checkin
                                </MenuItem>
                                <MenuItem onClick={() => showDetails()}>
                                  Ver detalhes
                                </MenuItem>
                              </Menu>
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={'Resultados por página'}
            />
          </Paper>
        }
      </Container>

      {openDetails ? (
        <DrawerDetailGuests {...itemSelected} close={closeDetails} getPDF={getPDF} />
      ) : null}

    </Box>
  );
}

export default GuestsScreen;
