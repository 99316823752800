import React, { useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  title,
  errorMessage,
  forgetPassword,
  forgetPasswordContainer,
  subtitleContainer,
  subtitle1,
  subtitle2,
  signup,
} from "./Login.style";
import * as userController from "../api/userController";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonLoading from "../components/layout/ButtonLoading";
import Copyright from "../components/Copyright";

import { TOKEN_KEY } from "../App";
import ButtonStyle from "../styles/button.style";
import { Button } from "@mui/material";

const theme = createTheme();

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState(false);

  let navigate = useNavigate();
  let location = useLocation();

  //let from = location.state?.from?.pathname || "/dashboard";

  const login = async () => {
    setLoading(true);
    setLoginError(false);

    userController.login(email, password)
      .then((response) => {
        setLoginError(false);
        localStorage.setItem(TOKEN_KEY, response.data.token);

        navigate("/splashscreen", { replace: true });
      })
      .catch((e) => {
        console.log(e);
        setLoginError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    login();
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          sm={false}
          md={7}
          sx={{
            backgroundImage: `url(${process.env.PUBLIC_URL + "/background-fives-login.png"
              })`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item sm={12} md={5} component={Paper} elevation={0} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              flex: 1,
            }}
          >
            <img
              src={process.env.PUBLIC_URL + "/fives.png"}
              height={56}
              width={149}
              alt="Fives"
              style={{ marginTop: 100 }}
            />

            <span style={title}>controller</span>

            <div style={subtitleContainer}>
              <span style={subtitle1}>Bem-vindo!</span> <br />
              <span style={subtitle2}>Conecte-se com seu email e senha</span>
            </div>

            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
              style={{ 
                fontFamily: "Poppins", 
                width: 400, 
              }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="E-mail"
                name="email"
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Senha"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />

              {loginError && (
                <span style={errorMessage}>
                  Erro ao realizar login. Verifique seus dados e tente
                  novamente.
                </span>
              )}

              <ButtonLoading label="Acessar" loading={loading} />

              <div style={forgetPasswordContainer}>
                <Link
                  href="/forgot-password"
                  variant="body2"
                  style={forgetPassword}
                >
                  Esqueci minha senha?
                </Link>
              </div>

              <Box
                sx={{
                  marginTop: 10,
                }}
              >
                <Button
                  variant='contained'
                  style={{ ...ButtonStyle.default, width: '100%' }}
                  elevation={1}
                  onClick={() => window.open("https://hospede.fivesapp.com.br", "_blank")}
                >
                  Criar conta
                </Button>

                <Copyright />
              </Box>
            </Box>

          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
