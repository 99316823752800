import React, { useState } from "react";
import { SwipeableDrawer, IconButton, TextField, Divider, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import TextStyle from "../../styles/text.style";
import InputFileImage from "../../components/inputfile/InputFileImage";
import SubTitle from "../../components/SubTitle";
import { saveHotelInfo } from "../../api/hotelController";
import { HOTEL_UUID } from "../../utils/constants";
import ButtonLoading from "../../components/layout/ButtonLoading";

const styles = {
  buttonClose: {
    background: "#C4C4C4",
    borderRadius: "6px",
    height: "32px",
    width: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "10px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    padding: "12px",
  },
  rowBetween: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "12px",
  },
  box: {
    padding: "20px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  padding: {
    padding: "12px",
  },
  itemBox: {
    width: "100%",
    marginBottom: "20px",
  },
  dateContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "20px",
  },
};

const drawerWidth = 600;

function DrawerInfo(props) {
  const [open, setOpen] = useState(true);
  const [logo, setLogo] = useState(props["logo"] || "");
  const [name, setName] = useState(props["name"] || "");
  const [email, setEmail] = useState(props["email"] || "");
  const [nameGoogle, setNameGoogle] = useState(props["name_google"] || "");
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [color, setColor] = useState(props["color"] || '#4177F6');
  const [site, setSite] = useState(props["site"] || "");
  const [passwordWifi, setPasswordWifi] = useState(props["passwordWifi"] || "");
  const [googlePlaceId, setGooglePlaceId] = useState(props["google_place_id"] || "");

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(open);
    props.close();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = new FormData();
    form.append("name", name);
    form.append("email", email);
    form.append("name_google", nameGoogle);
    form.append("file", file);
    form.append("logo", logo);
    form.append("color", color);
    form.append("site", site);
    form.append("passwordWifi", passwordWifi);
    form.append("google_place_id", googlePlaceId)

    setLoading(true);
    saveHotelInfo(localStorage.getItem(HOTEL_UUID), form)
      .then((response) => {
        toast.success("Dados atualizado com sucesso");
        props.close(true);
      })
      .catch((e) => {
        toast.error("Ocorreu um problema, tente mais tarde!");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      sx={{
        zIndex: "1300",
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
    >
      <div style={styles.row}>
        <div style={styles.buttonClose}>
          <IconButton component="span" onClick={toggleDrawer(false)}>
            <CloseIcon />
          </IconButton>
        </div>
        <span style={TextStyle.title}>Editar informações gerais</span>
      </div>
      <Divider />

      <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
        <SubTitle text="Logo" variant="subtitle2" />
        <InputFileImage setIcon={setLogo} icon={logo} setFile={setFile} />
      </div>

      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <div style={styles.box}>
          <SubTitle text="Informações" variant="subtitle2" />
          <TextField
            id="outlined-basic"
            label="Nome do hotel"
            variant="outlined"
            required
            style={styles.itemBox}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <TextField
            id="outlined-basic"
            label="E-mail do hotel"
            variant="outlined"
            required
            style={styles.itemBox}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <TextField
            id="outlined-basic"
            label="Site do Hotel"
            variant="outlined"
            required
            style={styles.itemBox}
            value={site}
            placeholder="https://www.hotel.com.br"
            onChange={(e) => setSite(e.target.value)}
          />

          <TextField
            id="outlined-basic"
            label="Nome no Google"
            variant="outlined"
            style={styles.itemBox}
            value={nameGoogle}
            onChange={(e) => setNameGoogle(e.target.value)}
          />

          <TextField
            id="outlined-basic"
            label="Identificador do hotel no Google"
            variant="outlined"
            style={styles.itemBox}
            value={googlePlaceId}
            onChange={(e) => setGooglePlaceId(e.target.value)}
          />

          <TextField
            id="outlined-basic"
            label="Senha Wifi"
            variant="outlined"
            required
            style={styles.itemBox}
            value={passwordWifi}
            onChange={(e) => setPasswordWifi(e.target.value)}
          />

          <Stack direction="row" spacing={1} alignItems="center">
            <TextField
              label="Cor principal do hotel"
              variant="outlined"
              value={color}
              fullWidth
              placeholder="Cor principal do hotel"
              onChange={(e) => {
                setColor(e.target.value)
              }}
            />

            <div style={{
              width: 55,
              height: 55,
              borderRadius: 5,
              backgroundColor: color
            }}></div>
          </Stack>

          <ButtonLoading label="Salvar" loading={loading} />
        </div>
      </form>
    </SwipeableDrawer>
  );
}

export default DrawerInfo;
