export const title = {
  fontFamily: 'Poppins',
  fontWeight: '600',
  fontSize: '19px',
  lineHeight: '28px',
  textAlign: 'center',
  letterSpacing: '0.4px',
  color: '#9FA2B4',
  opacity: 0.5,
  marginTop: '10px',
}

export const errorMessage = {
  color: 'red',
  fontSize: 12
}

export const forgetPasswordContainer = {
  with: '100%',
  alignItens: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  marginTop: 10,
}

export const forgetPassword = {
  fontFamily: 'Poppins',
  color: '#55CFFF'
}

export const signup = {
  fontFamily: 'Poppins',
  color: '#55CFFF',
  border: '1px solid #55CFFF',
  borderRadius: '4px',

}

export const subtitleContainer = {
  justifyContent: 'center',
  alignItens: 'center',
  textAlign: 'center',
  marginTop: 65
}

export const subtitle1 = {
  fontFamily: 'Poppins',
  color: '#000',
  fontSize: 15,
  fontWeight: 600,
  letterSpacing: '0.3px'
}

export const subtitle2 = {
  fontFamily: 'Poppins',
  fontSize: 14,
  fontWeight: 500,
  letterSpacing: '0.3px',
  color: '#9FA2B4'
}
