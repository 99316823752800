import React, { useState, useEffect } from 'react';
import { HOTEL_UUID } from '../../utils/constants';
import GuestsScreen from './Guests.screen';
import { getGuestsHotel } from '../../api/guestController';

export default function GuestsContainer() {
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    setLoading(true)
    fetchGuests()
  }, []);

  const reload = () => {
    setLoading(true)
    fetchGuests()
  }

  function fetchGuests() {
    getGuestsHotel(localStorage.getItem(HOTEL_UUID))
      .then(response => {
        setRows(response.data);
      })
      .catch(err => {
        console.log('err: ', err);
      })
      .finally(() => {
        setLoading(false)
      });
  }

  return (
    <GuestsScreen
      loading={loading}
      rows={rows}
      reload={reload}
    />
  )
}