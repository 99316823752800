import axios from 'axios';

export function saveListPrecheckin(payload) {
  return axios.post('/api/v1/precheckin-list', payload)
}

export function getAllPrecheckin(hotel_uuid) {
  return axios.get(`/api/v1/precheckin/hotel/${hotel_uuid}`)
}

export function deletePrecheckin(id) {
  return axios.delete(`/api/v1/precheckin/${id}`)
}

export function printPDF(hotel_uuid, precheckin_uuid) {
  return axios.get(`/api/v1/precheckin/${hotel_uuid}/${precheckin_uuid}`,
    {
      responseType: "blob",
    }
  )
}

export async function editPrecheckin(hotel_uuid, precheckin_uuid, data) {
  return axios.put(`/api/v1/precheckin/${hotel_uuid}/${precheckin_uuid}`, data)
}